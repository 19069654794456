import { FetchError } from './error';

export const responseFetcher = async (url: string, options?: RequestInit) => {
  let res: Response;
  try {
    res = await fetch(url, options);
  } catch (e) {
    throw FetchError.fromUnknownError(e, url);
  }

  if (!res.ok) {
    throw await FetchError.fromResponse(res);
  }

  return res;
};

export const jsonFetcher = async <DataType>(url: string, options?: RequestInit) => {
  return (await responseFetcher(url, options)).json() as DataType;
};
