import { ReactNode } from 'react';
import { Button, getButtonClassName } from '@/components/common/button/Button';
import classNames from 'classnames';
import { ExternalLink } from '@/components/links/ExternalLink';

// a "standard" (from a certain moment) modal with (possibly) icon, header, body and buttons list

type UniModalButtonProps<ButtonType> = {
  primary?: boolean;
  disabled?: boolean;
  caption: string;
  url?: string;
  value: ButtonType;
  autoClose?: boolean;
};

type UniModalButtonsProps<ButtonType> = {
  buttons?: UniModalButtonProps<ButtonType>[];
  onClose?: (value?: ButtonType) => void;
};

export type DefaultButtonValue = 'ok';
const defaultButtons: UniModalButtonProps<DefaultButtonValue>[] = [
  { primary: true, caption: 'OK', value: 'ok' },
];

function UniModalButtons<ButtonType>(props: UniModalButtonsProps<ButtonType>) {
  const buttons = props.buttons || (defaultButtons as UniModalButtonProps<ButtonType>[]);
  return (
    <div className="flex flex-col mt-8 gap-5">
      {buttons?.map((button, index) =>
        button.url ? (
          <ExternalLink
            key={index}
            caption={button.caption}
            className={getButtonClassName(!button.primary, 'text-center uppercase')}
            href={button.url}
            onClick={() => {
              props.onClose?.(button.value);
            }}
            showIcon={false}
          />
        ) : (
          <Button
            key={index}
            autoFocus={button.primary}
            secondary={!button.primary}
            disabled={button.disabled}
            className="uppercase"
            onClick={() => {
              props.onClose?.(button.value);
            }}
          >
            {button.caption}
          </Button>
        ),
      )}
    </div>
  );
}

export type UniModalProps<ButtonType> = {
  icon?: ReactNode;
  header: ReactNode;
  body: ReactNode;
  buttons?: UniModalButtonProps<ButtonType>[];
  onClose?: (value?: ButtonType) => void;
  closeByClickOnShade?: boolean;
};

export function UniModal<ButtonType>(props: UniModalProps<ButtonType>) {
  const hasIcon = !!props.icon;
  return (
    <div className="bg-white w-[328px] sm:w-[512px] p-8">
      <div className="flex flex-col text-darkGrey-600">
        {hasIcon && <div className="text-center mb-5">{props.icon}</div>}
        <h2 className={classNames('text-2xl font-medium mb-4', hasIcon ? 'text-center' : '')}>
          {props.header}
        </h2>
        {!hasIcon && <hr className="mb-3" />}
        <div className={classNames('pt-3', hasIcon ? 'text-center pb-8' : '')}>{props.body}</div>
        <UniModalButtons<ButtonType> buttons={props.buttons} onClose={props.onClose} />
      </div>
    </div>
  );
}
