import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { NavigationByLinkEvent } from './links';
import { ApprovalEvent } from './approvals';
import { SearchEvent } from './search';
import { ManualPrEvent } from './manualPr';

export type AnalyticsEvent = SearchEvent | NavigationByLinkEvent | ApprovalEvent | ManualPrEvent;
export type AnalyticsEventMap = {
  [K in AnalyticsEvent as K['name']]: Omit<K, 'name'>;
};

type CustomEventData = {
  properties?: { pageUrl?: string };
};

/**
 * Automatically attach pageUrl to all custom events.
 */
export function attachPageUrlToEvent(envelope: ITelemetryItem) {
  if (!envelope.name.endsWith('.Event')) return;
  const baseData = envelope.baseData as CustomEventData | undefined;
  if (!baseData) return;
  baseData.properties = Object.assign({}, baseData.properties, {
    pageUrl: window.location.href,
  });
}
